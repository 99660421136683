import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Severity } from "../../../shared/types/snackbar-types";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs";

@Injectable({
  providedIn: 'root'
})
/**
 * Service to manage snack bar notifications.
 */
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar,
              private _translateService: TranslateService) {
  }

  /**
   * Shows a message in the snack bar.
   * @param {string} message - The message to display.
   * @param {Severity} [severity] - The severity of the message (optional).
   * @param {boolean} [messageIsLangKey] - Indicates whether the message is a translation key (optional).
   * @param {number} [duration] - The duration of the message display (optional).
   */
  showMessage(message: string, severity?: Severity, messageIsLangKey?: boolean, duration?: number) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    if (severity) config.panelClass = severity;
    config.duration = duration || 2000;
    config.horizontalPosition = "center";
    this.openSnackbar(message, undefined, config, messageIsLangKey);
  }

  /**
   * Shows an error message in the snack bar.
   * @param {string} message - The error message to display.
   * @param {boolean} [messageIsLangKey] - Indicates whether the message is a translation key (optional).
   * @param {number} [duration] - The duration of the error message display (optional).
   */
  showErrorMessage(message: string, messageIsLangKey?: boolean, duration?: number) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.panelClass = "error";
    config.duration = duration || 2000;
    config.horizontalPosition = "center";
    this.openSnackbar(message, undefined, config, messageIsLangKey);
  }

  /**
   * Opens Mat Snackbar
   * @param message
   * @param action
   * @param config
   * @param messageIsLangKey
   * @private
   */
  private openSnackbar(message: string,
                       action?: string,
                       config?: MatSnackBarConfig,
                       messageIsLangKey?: boolean) {
    if (messageIsLangKey) {
      this._translateService.get(message).pipe(take(1)).subscribe(translation => {
        this._snackBar.open(translation, action, config);
      })
    } else {
      this._snackBar.open(message, action, config);
    }
  }
}
